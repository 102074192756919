import * as React from "react";

import "../styles/styles.scss";

import Careers from "../components/Careers/Careers.jsx";

const IndexPage = () => {
  return <Careers />;
};

export default IndexPage;
