import React, { useEffect } from "react";
import { Seo } from "../SEO/Seo.jsx";
import CTABanner from "../CTABanner/CTABanner";

import MainMenu from "../MainMenu/MainMenu";
import Footer from "../Footer/Footer.jsx";

import HeroSection from "./components/HeroSection.jsx";
import WhoWeLookingSection from "./components/WhoWeLookingSection.jsx";
import WhyJoinSection from "./components/WhyJoinSection.jsx";
import ValuesSection from "./components/ValuesSection.jsx";
import HowToStartSection from "./components/HowToStartSection.jsx";
import OpenPositionsSection from "./components/OpenPositionsSection.jsx";

import AOS from "aos";
import "aos/dist/aos.css";

const Careers = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    <main>
      <Seo
        title="Join Us | Gammasoft: Custom Software Development Company"
        description="See if you are the person we are looking for"
      />
      <MainMenu />
      <HeroSection />
      <WhoWeLookingSection />
      <WhyJoinSection />
      <ValuesSection />
      <HowToStartSection />
      <OpenPositionsSection />
      <CTABanner />
      <Footer />
    </main>
  );
};

export default Careers;
