import React from "react";
import { Link } from "gatsby";
import ArrowButton from "../../ArrowButton";
import CircleLightDotsImg from "../../../images/circle-light-with-dots.svg";
import SkewRectangleHeroImg from "../../../images/skewRectangleHero.png";

const HeroSection = () => {
  return (
    <section
      className="careers-hero"
      style={{ backgroundImage: `url(${SkewRectangleHeroImg})` }}
    >
      <img src={CircleLightDotsImg} alt="" data-aos="fade-down" />

      <div className="careers-hero-content container">
        <div className="careers-hero-content-left" data-aos="fade-up">
          <span>
            Career at <span>GammaSoft</span>
          </span>
          <h1 className="heading-primary">
            We offer opportunities for growth and development
          </h1>
          <p className="paragraph-primary">
            Great accomplishments in business cannot be attributed to a single
            individual. At GammaSoft, we are able to achieve great feats because
            we have an exceptional team of people.
          </p>
          <div className="careers-btn">
            <Link to="#open-positions" className="btn btn-secondary">
              <ArrowButton text="See current openings" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
