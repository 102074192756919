import React from "react";

import DotsLine from "../../../images/dots-line.svg";

const ValuesSection = () => {
  return (
    <section className="values-section">
      <div className="values-section-content container">
        <h2 className="heading-secondary" data-aos="fade-up">
          What values do we follow
        </h2>
        <p className="paragraph-primary" data-aos="fade-up" data-aos-delay={50}>
          People are our main value, and when you add a few valuable elements to
          the group, we have a recipe for great cooperation. Get to know us better and
          find out what you can get in the package when you join our team.
        </p>
        <img src={DotsLine} alt="" data-aos="fade-up" data-aos-delay={100} />
      </div>
      <div className="values-section-list container">
        <div
          className="values-section-list-item"
          data-aos="fade-up"
          data-aos-delay={150}
        >
          <h3 className="heading-third">Initiative</h3>
          <p className="paragraph-primary">
            We happily assist those who want to give their ideas a shot. Every
            employee is able to contribute to the company's vision.
          </p>
        </div>
        <div
          className="values-section-list-item"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <h3 className="heading-third">Atmosphere</h3>
          <p className="paragraph-primary">
            You can feel right away that you're in good hands, because we focus
            on welcoming new employees, as well as introducing the company to them.
          </p>
        </div>
        <div
          className="values-section-list-item"
          data-aos="fade-up"
          data-aos-delay={250}
        >
          <h3 className="heading-third">Openness</h3>
          <p className="paragraph-primary">
            We are open to discussions with our employees. We value your input.
            Please contact us, if you have any thoughts to share.
          </p>
        </div>
        <div
          className="values-section-list-item"
          data-aos="fade-up"
          data-aos-delay={300}
        >
          <h3 className="heading-third">Professional Development</h3>
          <p className="paragraph-primary">
            You can learn from others and take part in technical training, as
            well as level up your English skills.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;
