import React from "react";

import ArrowRightColor from "../../../images/arrow-right-color.svg";

const HowToStartSection = () => {
  return (
    <section className="how-to-start-section">
      <div className="how-to-start-section-content container">
        <h2 className="heading-secondary" data-aos="fade-up">
          How to start working with us?
        </h2>
        <p className="paragraph-primary" data-aos="fade-up" data-aos-delay={50}>
          Take a look at our hiring process, to get ready to work with us.
        </p>
      </div>
      <ul className="how-to-start-section-steps container">
        <li
          className="how-to-start-section-steps-single-first"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <span>01</span>
          <h3 className="heading-third">Apply</h3>
          <p className="paragraph-primary">
            Please let us know if you are interested. Tell us about your
            experience and the kind of work you enjoy doing.
          </p>
          <img src={ArrowRightColor} alt="" />
        </li>
        <li
          className="how-to-start-section-steps-single-second"
          data-aos="fade-up"
          data-aos-delay={150}
        >
          <span>02</span>
          <h3 className="heading-third">Let's talk</h3>
          <p className="paragraph-primary">
            This meeting will explore GammaSoft's recruitment process, work
            environment, and why working for GammaSoft is such an incredible
            experience. We're happy to answer any questions you may have. Please
            don't hesitate to ask anything, and we'll be glad to address it.
          </p>
        </li>
        <li
          className="how-to-start-section-steps-single-third"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <img
            src={ArrowRightColor}
            alt=""
            className="how-to-start-img-first"
          />
          <span>03</span>
          <h3 className="heading-third">Challenge yourself</h3>
          <p className="paragraph-primary">
            If you pass the first round, you will be given a technical task to
            perform on the day of your choice. Our group developed some tasks for
            this purpose.
          </p>
          <img
            src={ArrowRightColor}
            alt=""
            className="how-to-start-img-second"
          />
        </li>
        <li
          className="how-to-start-section-steps-single-fourth"
          data-aos="fade-up"
          data-aos-delay={250}
        >
          <span>04</span>
          <h3 className="heading-third">Welcome on board!</h3>
          <p className="paragraph-primary">
            After the technical assessment is finished, and you are accepted, you will begin the process of onboarding. 
            But you may still talk to our staff, if you have any doubts or questions.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default HowToStartSection;
