import React from "react";

const WhoWeLookingSection = () => {
  return (
    <section className="who-we-looking-for-section">
      <div className="who-we-looking-for-section-content container">
        <div
          className="who-we-looking-for-section-content-left"
          data-aos="fade-right"
        >
          <h2 className="heading-secondary">
            Learn about our ideal candidates
          </h2>
        </div>
        <div
          className="who-we-looking-for-section-content-right"
          data-aos="fade-left"
        >
          <p className="paragraph-primary">
            Are you passionate about technology and eager to learn and grow? Are
            you a team player who enjoys working with others to achieve great
            things? If so, we want to hear from you! At our company, we are
            always looking for talented and motivated individuals to join our
            team.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhoWeLookingSection;
