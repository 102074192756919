import React from "react";

const WhyJoinSection = () => {
  return (
    <section className="why-join-us-section">
      <div className="why-join-us-section-content container">
        <h2 className="heading-secondary" data-aos="fade-up">
          Why should you <span>join us?</span>
        </h2>
        <p
          className="paragraph-primary"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          See what we can offer you and not just expect.
        </p>
        <div className="why-join-us-section-list container">
          <div
            className="why-join-us-section-list-item"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <p>Remote work</p>
          </div>
          <div
            className="why-join-us-section-list-item"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <p>Working comfort</p>
          </div>
          <div
            className="why-join-us-section-list-item"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <p>Professional development</p>
          </div>
          <div
            className="why-join-us-section-list-item"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <p>Unrestricted choice of the form of employment</p>
          </div>
          <div
            className="why-join-us-section-list-item"
            data-aos="fade-up"
            data-aos-delay={500}
          >
            <p>Great work autonomy</p>
          </div>
          <div
            className="why-join-us-section-list-item"
            data-aos="fade-up"
            data-aos-delay={600}
          >
            <p>Excellent team communication</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyJoinSection;
